import Localbase from 'localbase'
import {isNumber} from "lodash/lang";

const EventEmitter = require('events');
class MyEmitter extends EventEmitter {}
let db = new Localbase('nvlss');
//let dbQ = new Localbase('workbox-background-sync');
db.config.debug = false;
const serverURL = 'https://drivers.nvlss.com';
const readDelay = 500;
const ordersDbName = "orders";
const completedOrdersDbName = "completedOrders";
const queuedOrdersDbName = "orderQue";
const settingsDbName = "settings";
const userDbName = "user";
const newPhotosDbName = "newPhotos";
const capturedPhotosDbName = "capturedPhotos";
const getNewOrdersAPIUrl = "/driver/action/getDriversNewOrders?utm_driver_id=";
const getCompletedOrdersAPIUrl = "/driver/action/getDriversCompletedOrders?utm_driver_id=";
const getUploadBulkImagesUrl = "/order/photos/add"
const getCapturedPhotoUploadUrl = '/order/photo/add'
const getSettingsAPIUrl = "/driver/action/getSettings";
const getNewUserAPIUrl = "/driver/action/getUserData";
const checkConnectivityAPIUrl = "/driver/action/checkConnectivity";
const updateRemoteOrderAPIUrl = "/order/action/updateOrder";
const updateRemoteBulkOrdersAPIUrl = "/order/action/updateOrderBulk";
//let isLocalDbFinishedWithSync = false;
window.currentUserName = "";
window.currentUserID = "";
window.userSms = 0;
window.NewOrderData = [];
window.OrderQue = [];
window.photos = [];
window.capturedPhotos = [];
window.CurrentOrder = [];
window.lastOrderToMoveToCompletedFromQue = "";
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
//:::                                                                         :::
//:::  This routine calculates the distance between two points (given the     :::
//:::  latitude/longitude of those points). It is being used to calculate     :::
//:::  the distance between two locations using GeoDataSource (TM) products  :::
//:::                                                                         :::
//:::  Definitions:                                                           :::
//:::    South latitudes are negative, east longitudes are positive           :::
//:::                                                                         :::
//:::  Passed to function:                                                    :::
//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
//:::    unit = the unit you desire for results                               :::
//:::           where: 'M' is statute miles (default)                         :::
//:::                  'K' is kilometers                                      :::
//:::                  'N' is nautical miles                                  :::
//:::                                                                         :::
//:::  Worldwide cities and other features databases with latitude longitude  :::
//:::  are available at https://www.geodatasource.com                         :::
//:::                                                                         :::
//:::  For enquiries, please contact sales@geodatasource.com                  :::
//:::                                                                         :::
//:::  Official Web site: https://www.geodatasource.com                       :::
//:::                                                                         :::
//:::               GeoDataSource.com (C) All Rights Reserved 2018            :::
//:::                                                                         :::
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
/**
 * Calculates the distance between two geographical points.
 *
 * @param {number} lat1 - Latitude of the first point.
 * @param {number} lon1 - Longitude of the first point.
 * @param {number} lat2 - Latitude of the second point.
 * @param {number} lon2 - Longitude of the second point.
 * @param {string} [unit='M'] - Unit for the distance: 'M' (Miles), 'K' (Kilometers), 'N' (Nautical Miles).
 * @returns {number} - The distance between the two points in the specified unit.
 */
window.distance = (lat1, lon1, lat2, lon2, unit = 'M') => {
    if (lat1 === lat2 && lon1 === lon2) {
        return 0;
    }

    const toRadians = degrees => (Math.PI * degrees) / 180;

    const radlat1 = toRadians(lat1);
    const radlat2 = toRadians(lat2);
    const radtheta = toRadians(lon1 - lon2);

    let dist = Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

    dist = Math.acos(Math.min(dist, 1)); // Ensure dist value doesn't exceed 1 due to floating-point errors
    dist = (dist * 180) / Math.PI; // Convert from radians to degrees
    dist = dist * 60 * 1.1515; // Convert degrees to miles

    // Convert miles to the desired unit
    if (unit === 'K') {
        dist *= 1.609344; // Convert miles to kilometers
    } else if (unit === 'N') {
        dist *= 0.8684; // Convert miles to nautical miles
    }

    return dist;
};
/**
 * Checks if the local database (IndexedDB) is supported in the current browser.
 *
 * @returns {boolean} - Returns `true` if IndexedDB is supported, otherwise `false`.
 */
window.isLocalDBSupported = () => {
    window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
    window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction || { READ_WRITE: "readwrite" };
    window.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;

    // Return a boolean indicating whether IndexedDB is supported
    return Boolean(window.indexedDB);
};
/**
 * Checks if a given URL is reachable by performing a `HEAD` request.
 *
 * @param {string} url - The URL to check for reachability.
 * @returns {Promise<boolean>} - Resolves to `true` if the URL is reachable, otherwise `false`.
 */
window.isReachable = async (url) => {
    try {
        // Perform a fetch request with method 'HEAD' and mode 'no-cors'
        const response = await fetch(url, { method: 'HEAD', mode: 'no-cors' });

        // Determine reachability based on the response
        return response && (response.ok || response.type === 'opaque');
    } catch (err) {
        console.warn('[Connection test failure]:', err);
        return false;
    }
};

window.getCacheStoragesAssetTotalSize = async function () {
    // Note: opaque (i.e. cross-domain, without CORS) responses in the cache will return a size of 0.
    const cacheNames = await caches.keys();
    let total = 0;

    const sizePromises = cacheNames.map(async cacheName => {
        const cache = await caches.open(cacheName);
        const keys = await cache.keys();
        let cacheSize = 0;

        await Promise.all(keys.map(async key => {
            const response = await cache.match(key);
            const blob = await response.blob();
            total += blob.size;
            cacheSize += blob.size;
        }));

        console.log(`Cache ${cacheName}: ${cacheSize} bytes`);
    });

    await Promise.all(sizePromises);
    return window.formatBytes(total);
}

/**
 * Formats a number of bytes into a human-readable string with the appropriate unit.
 *
 * @param {number} bytes - The number of bytes to format.
 * @param {number} [decimals=2] - The number of decimal places to include. Defaults to 2.
 * @returns {string} - A formatted string with the size in a human-readable unit (e.g., "1.23 KB").
 */
window.formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const dm = Math.max(0, decimals); // Ensure decimals is not negative

    const formattedValue = (bytes / Math.pow(k, i)).toFixed(dm);

    return `${formattedValue} ${sizes[i]}`;
};
/**
 * Retrieves the value of a query parameter from a given URL.
 *
 * @param {string} name - The name of the parameter to retrieve.
 * @param {string} [url=window.location.href] - The URL to search in. Defaults to the current window's URL.
 * @returns {string|null} - The parameter's value if present, an empty string if it exists without a value, or `null` if not found.
 */
window.getParameterByName = (name, url = window.location.href) => {
    const escapedName = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${escapedName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);

    if (!results) return null; // Parameter not found in URL
    if (!results[2]) return ''; // Parameter exists but has no value

    return decodeURIComponent(results[2].replace(/\+/g, ' ')); // Decode and return the parameter's value
};

/**
 * Recursively finds objects in a given object tree based on the provided key and/or value.
 *
 * @param {Object} obj - The object to search through.
 * @param {string} key - The key to match (can be empty to match by value only).
 * @param {*} val - The value to match (can be empty to match by key only).
 * @returns {Array} - An array of objects matching the criteria.
 */
window.getObjects = function (obj, key = '', val = '') {
    const results = [];

    // Helper function for deep traversal
    function traverse(currentObj) {
        for (const prop in currentObj) {
            if (!Object.prototype.hasOwnProperty.call(currentObj, prop)) continue;

            // Check for nested objects and traverse recursively
            if (typeof currentObj[prop] === 'object' && currentObj[prop] !== null) {
                traverse(currentObj[prop]);
            }

            // Add object if the key and/or value match the criteria
            if (
                (prop === key && (currentObj[prop] == val || val === '')) || // Match by key and value (or key only if value is empty)
                (currentObj[prop] == val && key === '') // Match by value only
            ) {
                if (!results.includes(currentObj)) {
                    results.push(currentObj);
                }
            }
        }
    }

    traverse(obj);
    return results;
};


/**
 * Recursively retrieves all values associated with the specified key in a given object tree.
 *
 * @param {Object} obj - The object to search through.
 * @param {string} key - The key whose values are to be retrieved.
 * @returns {Array} - An array of all values found for the specified key.
 */
window.getValues = function (obj, key) {
    const values = [];

    // Helper function for recursive traversal
    function traverse(currentObj) {
        for (const prop in currentObj) {
            if (!Object.prototype.hasOwnProperty.call(currentObj, prop)) continue;

            // If the property is an object, recursively traverse it
            if (typeof currentObj[prop] === 'object' && currentObj[prop] !== null) {
                traverse(currentObj[prop]);
            }

            // If the key matches, add its value to the results
            if (prop === key) {
                values.push(currentObj[prop]);
            }
        }
    }

    // Start traversal
    traverse(obj);
    return values;
};

/**
 * Recursively retrieves all keys in a given object tree that have a specific value.
 *
 * @param {Object} obj - The object to search through.
 * @param {*} val - The value to match.
 * @returns {Array} - An array of all keys that match the specified value.
 */
window.getKeys = function (obj, val) {
    const keys = [];

    // Helper function for recursive traversal
    function traverse(currentObj) {
        for (const prop in currentObj) {
            if (!Object.prototype.hasOwnProperty.call(currentObj, prop)) continue;

            // If the property is an object, recursively traverse it
            if (typeof currentObj[prop] === 'object' && currentObj[prop] !== null) {
                traverse(currentObj[prop]);
            }

            // If the value matches, add the key to the results
            if (currentObj[prop] === val) {
                keys.push(prop);
            }
        }
    }

    // Start traversal
    traverse(obj);
    return keys;
};


window.addLog = (logEntry) => {
    let statusWindow = $('#appLoader');
    statusWindow.html(statusWindow.html() + "<br>" + logEntry)
}
window.getServerUrl = () => {return serverURL;}

/**
 * Collects and displays device and browser information in HTML format.
 * @returns {string} - HTML string containing OS and browser details along with navigator properties.
 */
window.GetHtmlToDisplayDeviceInfo = () => {
    'use strict';

    // Module to identify OS and browser details
    const module = {
        header: [
            navigator.platform,
            navigator.userAgent,
            navigator.appVersion,
            navigator.vendor,
            window.opera
        ],
        dataOS: [
            { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
            { name: 'Windows', value: 'Win', version: 'NT' },
            { name: 'iPhone', value: 'iPhone', version: 'OS' },
            { name: 'iPad', value: 'iPad', version: 'OS' },
            { name: 'Kindle', value: 'Silk', version: 'Silk' },
            { name: 'Android', value: 'Android', version: 'Android' },
            { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
            { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
            { name: 'Macintosh', value: 'Mac', version: 'OS X' },
            { name: 'Linux', value: 'Linux', version: 'rv' },
            { name: 'Palm', value: 'Palm', version: 'PalmOS' }
        ],
        dataBrowser: [
            { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
            { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
            { name: 'Safari', value: 'Safari', version: 'Version' },
            { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
            { name: 'Opera', value: 'Opera', version: 'Opera' },
            { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
            { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
        ],

        // Initialize and collect OS and browser details
        init: function () {
            const agent = this.header.join(' ');
            const os = this.matchItem(agent, this.dataOS);
            const browser = this.matchItem(agent, this.dataBrowser);
            return { os, browser };
        },

        // Match the user agent string against data items to find OS or browser
        matchItem: function (agentString, data) {
            for (const { name, value, version } of data) {
                const regex = new RegExp(value, 'i');
                if (regex.test(agentString)) {
                    const regexVersion = new RegExp(`${version}[- /:;]([\\d._]+)`, 'i');
                    const matches = agentString.match(regexVersion);
                    let versionNumber = matches && matches[1]
                        ? matches[1].split(/[._]+/).join('.')
                        : '0'; // Default version when not found

                    return {
                        name,
                        version: parseFloat(versionNumber)
                    };
                }
            }
            return { name: 'unknown', version: 0 };
        }
    };

    // Invoke the module to retrieve device and browser details
    const { os, browser } = module.init();

    // Compose the result HTML
    return `
        os.name = ${os.name}<br/>
        os.version = ${os.version}<br/>
        browser.name = ${browser.name}<br/>
        browser.version = ${browser.version}<br/><br/>
        navigator.userAgent = ${navigator.userAgent}<br/>
        navigator.appVersion = ${navigator.appVersion}<br/>
        navigator.platform = ${navigator.platform}<br/>
        navigator.vendor = ${navigator.vendor}<hr/>
    `;
};

/**
 * Register event listener for 'finishedPopulatingLocalOrdersDB'.
 * Retrieves new orders from the database and sets them to the global window.Neworders array.
 * Emits the 'GetLocalNewOrdersDBReadyEvent' once the data is ready.
 */
window.myEmitter = new MyEmitter();

myEmitter.on('finishedPopulatingLocalOrdersDB', async () => {
    try {
        window.Neworders = await db.collection(ordersDbName).get();
        myEmitter.emit('GetLocalNewOrdersDBReadyEvent');
    } catch (error) {
        console.error('Error retrieving new orders:', error);
    }
});

/**
 * Event listener for 'finishedPopulatingCompletedOrdersDB'.
 * Retrieves completed orders from the database and sets them to the global `window.Completedorders` array.
 * Emits the 'GetLocalCompletedOrdersDBReadyEvent' once the data is ready.
 */
myEmitter.on('finishedPopulatingCompletedOrdersDB', async () => {
    try {
        window.Completedorders = await db.collection(completedOrdersDbName).get();
        myEmitter.emit('GetLocalCompletedOrdersDBReadyEvent');
    } catch (error) {
        console.error('Error retrieving completed orders:', error);
    }
});

/**
 * Event listener for 'readyToGetQueuedOrders'.
 * Retrieves queued orders from the database and sets them to the global `window.OrderQue` array.
 * Emits the 'finishedGettingOrderQue' event once the data is ready.
 */
myEmitter.on('readyToGetQueuedOrders', async () => {
    try {
        window.OrderQue = await db.collection(queuedOrdersDbName).get();
        myEmitter.emit('finishedGettingOrderQue');
    } catch (error) {
        console.error('Error retrieving queued orders:', error);
    }
});

/**
 * Fires the window.getNewOrder(orderID) function;
 * @param orderID
 */
window.getOrder = (orderID) => { window.getNewOrder(orderID);}

/**
 * Sync Orders with the NVLSS Server.
 * Fetches new orders and puts the response in the local orders collection.
 * @param {string|number} driverID - The user ID of the driver.
 */
window.syncOrders = async (driverID) => {
    try {
        // Ensure driverID exists; otherwise, fetch it from the local user collection.
        driverID = isNumber(driverID) ? driverID : await fetchCurrentUserID();

        // Fetch new orders from NLN Server
        const response = await fetch(`${getNewOrdersAPIUrl}${driverID}`);
        const jsonResponse = await response.json();

        // Put new orders in the local orders collection
        await window.putRemoteOrdersInLocalDB(jsonResponse);
    } catch (error) {
        console.error('Error syncing orders:', error);
    }
};

/**
 * Fetches and returns the current user ID from the local user collection.
 * @returns {Promise<string|number|null>} - The current user ID or `null` if not found.
 */
const fetchCurrentUserID = async () => {
    try {
        const [user] = await db.collection(userDbName).get();
        window.currentUserID = user?.id || null;
        return window.currentUserID;
    } catch (error) {
        console.error('Error fetching current user ID:', error);
        return null;
    }
};


/**
 * Generate a unique 9 character key
 */
window.getUniqueKey = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
};

/**
 * Retrieves the current user document and sets global variables
 * (`window.currentUserID`, `window.currentUserName`, `window.userSms`).
 */
window.getUserData = async () => {
    try {
        const [user] = await db.collection(userDbName).get();

        if (user) {
            window.currentUserID = user.id;
            window.currentUserName = user.name;
            window.userSms = user.opt_in_sms;
        } else {
            console.warn('No user found in the collection.');
        }
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
};

/**
 * Retrieves the current user document, sets global variables, and updates the side menu.
 * Updates `window.currentUserID`, `window.currentUserName`, and `window.userSms`, and sets
 * the user's name and SMS preference in the UI.
 */
window.populateUserTableLocal = async () => {
    try {
        const [user] = await db.collection(userDbName).get();

        if (user) {
            // Update global variables
            window.currentUserID = user.id;
            window.currentUserName = user.name;
            window.userSms = user.sms;

            // Update the UI elements
            $('#menuUserName').append(`<br>${window.currentUserName}`);
            if (window.userSms === 1) {
                $('#receiveSMS').prop('checked', true);
            }
        } else {
            console.warn('No user found in the collection.');
        }
    } catch (error) {
        console.error('Error populating user table locally:', error);
    }
};


/**
 * Fetches the current user data from the NVLSS server and sets global variables.
 * Updates `window.currentUserName`, `window.currentUserID`, and `window.userSms`, and updates the UI.
 * Emits the `UserDataGathered` event after processing the user data.
 * @event window#UserDataGathered
 */
window.populateUserTable = async () => {
    try {
        // Fetch user data from the NVLSS server
        const response = await fetch(getNewUserAPIUrl);
        const jsonResponse = await response.json();

        // Insert fetched user data into the local user collection
        await db.collection(userDbName).add(jsonResponse, String(jsonResponse.id));

        // Update global variables
        const { name, id, sms } = jsonResponse;
        window.currentUserName = name;
        window.currentUserID = id;
        window.userSms = sms;

        // Update the UI
        $('#menuUserName').append(`<br>${name}`);
        if (sms === 1) {
            $('#receiveSMS').prop('checked', true);
        }

        // Emit the custom event after processing user data
        setTimeout(() => myEmitter.emit('UserDataGathered'), readDelay);
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
};

/**
 * Fetches the application settings from the NVLSS server and populates
 * the local settings collection with the response.
 */
window.populateSettingsTable = async () => {
    try {
        // Fetch settings data from the NVLSS server
        const response = await fetch(getSettingsAPIUrl);
        const jsonResponse = await response.json();

        // Populate the local settings collection
        jsonResponse.forEach((setting) => {
            db.collection(settingsDbName).add(setting, setting.key);
        });
    } catch (error) {
        console.error('Error fetching or populating settings:', error);
    }
};


/**
 * Fetches the last 20 completed orders for a user (driver) and populates the local
 * completedOrders collection with the response.
 * @param {String} driverID - The user ID of the driver.
 */
window.getCompletedOrders = async (driverID) => {
    try {
        // Verify if `driverID` is a number; otherwise fetch the current user ID
        const validDriverID = isNumber(driverID)
            ? driverID
            : (await db.collection(userDbName).get())[0]?.id;

        if (!validDriverID) {
            console.warn('Invalid driverID or no current user found.');
            return;
        }

        // Fetch completed orders from the server
        const response = await fetch(`${getCompletedOrdersAPIUrl}${validDriverID}`);
        const jsonResponse = await response.json();

        // Populate the local database with completed orders
        window.putCompletedOrdersInLocalDB(jsonResponse);

        // Update user table if `driverID` was not initially valid
        if (!isNumber(driverID)) {
            window.populateUserTable();
        }
    } catch (error) {
        console.error('Error fetching completed orders:', error);
    }
};

/**
 * Adds an image object to the `newPhotosDbName` and `capturedPhotosDbName` collections.
 * @param {object} imageObject - The image object to add.
 * @returns {string|null} The unique key of the stored document in the `newPhotosDbName` collection.
 */
    window.addImageToLocalDB = (imageObject) => {
    if (!Array.isArray(imageObject) || imageObject.length === 0) {
        console.warn('Invalid image object provided.');
        return null;
    }

    // Assign a unique key to the image object
    const uniqueKey = window.getUniqueKey();
    imageObject[0].key = uniqueKey;

    console.log('Added To Captured Photos');

    // Add the image object to both collections
    const imageDocument = imageObject[0];
    db.collection(capturedPhotosDbName).doc('nlnorder').add(imageDocument);
    db.collection(newPhotosDbName).doc('nlnorder').add(imageDocument);

    return uniqueKey;
};





/**
 * Deletes an image document by key from the `newPhotosDbName` collection.
 * @param {string} imageKey - The key of the image document to delete.
 */
window.deleteImageFromLocalDB = (imageKey) => {
    if (!imageKey) {
        console.warn('Invalid image key provided.');
        return;
    }

    // Delete the image document by key
    db.collection(newPhotosDbName).doc({ key: imageKey }).delete()
        .then(() => {
            console.log(`Image with key ${imageKey} successfully deleted.`);
        })
        .catch((error) => {
            console.error(`Error deleting image with key ${imageKey}:`, error);
        });
};

/**
 * Adds a completed orders array to the `completedOrdersDbName` collection.
 * @param {object[]} data - The orders object array.
 */
    window.putCompletedOrdersInLocalDB = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
        console.warn('No valid data provided to populate the completed orders database.');
        return;
    }

    // Process the completed orders data
    const completedOrderObjects = data.map(order => {
        let orderClass = "none";
        const daysTillDue = order.due_in_days;

        if (daysTillDue === 0) {
            orderClass = "dueToday";
        } else if (daysTillDue < 0) {
            orderClass = "pastDue";
        }

        const postProperty = order.json_form_options?.Post !== false;

        return {
            ...order,
            orderClass,
            _key: String(order.id),
            postProperty
        };
    });

    // Save the completed orders to the database
    setTimeout(() => {
        db.collection(completedOrdersDbName)
            .set(completedOrderObjects, { keys: true })
            .then(() => myEmitter.emit('finishedPopulatingCompletedOrdersDB'))
            .catch(error => console.error('Error populating completed orders DB:', error))
            .finally(() => $('#wait').fadeOut());
    }, readDelay);
};

/**
 * Updates order in indexedDB
 * @param  {object} orderData The order object array
 */
window.updateOrderInLocalDB = async (orderData) => {
    try {
        console.log('Updating Order Data');

        // Update the document in the database
        await db.collection(ordersDbName).doc({ id: orderData.id }).update(orderData);

        // Fetch the updated document
        window.CurrentOrder = await db.collection(ordersDbName).doc({id: orderData.id}).get();

        // Emit success event
        myEmitter.emit('orderSavedToLocalDbSuccess');
    } catch (error) {
        // Handle errors and emit failure event
        console.error('Order update failed:', error);
        myEmitter.emit('orderSavedToLocalDbFailure');
    }
}

/**
 * Event Listener for Queued Orders Saved to Remote Server.
 */
myEmitter.on('BulkOrderSavedToRemoteServerFromQue', async () => {
    try {
        // Fetch queued orders
        const orderObjects = await db.collection(queuedOrdersDbName).get();

        if (!Array.isArray(orderObjects) || orderObjects.length === 0) {
            console.warn('No queued orders found.');
            myEmitter.emit('bulkOrdersAddedToCompletedLocalBD');
            $('#wait').fadeOut(10);
            return;
        }

        for (const [index, order] of orderObjects.entries()) {
            const id = order.id;

            // Move order from queue to completed
            window.lastOrderToMoveToCompletedFromQue = id;
            await window.moveOrderFromQueToCompletedByID(id, order);

            // Emit event for moving completed order
            myEmitter.emit('MoveLastCompletedOrderToCompletedTab');

            // Emit bulk completed event after processing the last order
            if (index === orderObjects.length - 1) {
                setTimeout(() => {
                    myEmitter.emit('bulkOrdersAddedToCompletedLocalBD');
                }, readDelay);
                $('#wait').fadeOut(10);
            }
        }

        // Sync the list totals after processing
        myEmitter.emit('syncListTotals');
    } catch (error) {
        console.error('Error processing queued orders:', error);
    }
});

/**
 * Moves an order from the local queue to the completed database by order ID.
 * @param {string} orderID - The ID of the order.
 * @param {object} orderObject - The order object.
 */
window.moveOrderFromQueToCompletedByID = async (orderID, orderObject) => {
    try {
        // Add order to the completed orders database
        await db.collection(completedOrdersDbName).add(orderObject, String(orderID));

        // Remove the order from the queued orders database
        await db.collection(queuedOrdersDbName).doc({ id: orderID }).delete();

        // Emit event to update list totals
        myEmitter.emit('syncListTotals');
    } catch (error) {
        // Log error and provide fallback actions if needed
        console.error('Error moving order from queue to completed:', error);
    }
};

/**
 * Moves an order from the local queue to the completed database using the order ID.
 * @param {string} orderID - The ID of the order to move.
 */
    window.moveOrderFromQueToCompleted = async (orderID) => {
    try {
        const currentOrder = window.CurrentOrder;

        if (!currentOrder || currentOrder.id !== orderID) {
            console.warn('Order ID does not match the current order or is missing.');
            return;
        }

        // Add the current order to the completed orders database
        await db.collection(completedOrdersDbName).add(currentOrder, String(currentOrder.id));
        myEmitter.emit('orderAddedToCompletedLocalBD');

        // Remove the order from the queued orders database
        await db.collection(queuedOrdersDbName).doc({ id: currentOrder.id }).delete();
        myEmitter.emit('syncListTotals');
    } catch (error) {
        console.error('Error while moving order from queue to completed:', error);
    }
};

/**
 * Moves an order from the "New Orders" database to the "Completed Orders" database using the order ID.
 * @param {string} orderID - The ID of the order to move.
 */
window.moveOrderFromNewToCompleted = async (orderID) => {
    try {
        const currentOrder = window.CurrentOrder;

        if (!currentOrder || currentOrder.id !== orderID) {
            console.warn('Order ID does not match the current order or is missing.');
            return;
        }

        // Add the current order to the completed orders database
        await db.collection(completedOrdersDbName).add(currentOrder, String(currentOrder.id));
        myEmitter.emit('orderAddedToCompletedLocalBD');

        // Remove the order from the new orders database
        await db.collection(ordersDbName).doc(String(currentOrder.id)).delete();
        myEmitter.emit('syncListTotals');
    } catch (error) {
        console.error('Error while moving order from new to completed:', error);
    }
};

/**
 * Moves an order from the "New Orders" database to the "Queue" database using the order ID.
 * @param {string} orderID - The ID of the order to move.
 */
window.moveOrderFromNewToQue = async (orderID) => {
    try {
        const currentOrder = window.CurrentOrder;

        if (!currentOrder || currentOrder.id !== orderID) {
            console.warn('Order ID does not match the current order or is missing.');
            return;
        }

        // Add the current order to the queued orders database
        await db.collection(queuedOrdersDbName).add(currentOrder, String(currentOrder.id));
        myEmitter.emit('orderAddedToQueuedLocalBD');

        // Remove the order from the new orders database
        await db.collection(ordersDbName).doc({ id: currentOrder.id }).delete();
        myEmitter.emit('orderMovedToQue');
        myEmitter.emit('syncListTotals');
    } catch (error) {
        console.error('Error while moving order from new to queue:', error);
    }
};


/**
 * Displays a queued order with necessary details as an HTML card.
 * @param {object} order - The order details.
 */
window.displayQueuedOrder = (order) => {
    const {
        orderClass,
        due_in_days: daysTillDue,
        json_form_options: options,
        postProperty,
        id,
        description,
        address,
        zip,
        City
    } = order;

    if (postProperty) {
        const orderCardHtml = `
            <div id="divOrderList-${id}" class="card-option" data-distance="">
                <div style="background-color: #e91e53; width: 100%;">
                    <div class="orderType">${description}</div>
                    <div class="address">
                        <a href="https://maps.google.com/?daddr=${address}">
                            <img class="map" src="../images/googlemaps.png">
                        </a>
                        <span style="color: #ffcc00" class="editLink">${address}</span>
                        <div class="distance">
                            <div id="${id}" style="position: relative; float: right;">XX mi</div>
                        </div>
                    </div>
                    <div class="detailsBox">
                        <div class="zipcode">ID: ${id}</div>
                        <div class="zipcode ${orderClass}" style="width: 60%;">DUE: ${daysTillDue}</div>
                        <div class="zipcode">ZIP: ${zip}</div>
                        <div class="zipcode" style="width: 60% !important;">CITY: ${City}</div>
                    </div>
                </div>
            </div>
        `;

        $('#ordersWrapper3').append(orderCardHtml);
    }
};

/**
 * Processes queued orders from the local database and sends them to the remote server.
 */
window.processQueuedOrder = async () => {
    try {
        $('#wait').fadeIn(10);

        // Retrieve all orders from the queued orders database
        const ordersQue = await db.collection(queuedOrdersDbName).get();

        // Send queued orders to the remote server
        const response = await fetch(updateRemoteBulkOrdersAPIUrl, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr('content')
            },
            method: "POST",
            credentials: "same-origin",
            body: JSON.stringify(ordersQue)
        });

        // Handle the response
        if (response.ok) {
            myEmitter.emit('BulkOrderSavedToRemoteServerFromQue');
        } else {
            myEmitter.emit('ErrorUpdatingRemoteDB');
        }
    } catch (error) {
        console.error('Error processing queued orders:', error);
        myEmitter.emit('ErrorUpdatingRemoteDB');
    } finally {
        $('#wait').fadeOut(10);
    }
};

/**
 * Sets the visibility of the queued photo indicator based on the presence of photos in the database.
 */
window.setQueuedPhotoIndicator = async () => {
    try {
        const documents = await db.collection(newPhotosDbName).get();

        // Toggle the queued photo indicator based on the existence of documents
        if (documents.length === 0) {
            $('#queuedPhotoIndicator').fadeOut(20);
        } else {
            $('#queuedPhotoIndicator').fadeIn(20);
        }
    } catch (error) {
        console.error('Error checking queued photos:', error);
    }
};

/**
 * Updates an order from the queue on the NLN server.
 * @param {object} orderData - The order data to update.
 * @returns {boolean} Returns false if no order data is provided.
 */
window.updateOrderOnNLNServerFromQue = async (orderData) => {
    try {
        if (!orderData) {
            return false;
        }

        // Fetch the current order from the queued orders database
        const document = await db.collection(queuedOrdersDbName).doc({ id: +orderData.id }).get();
        window.CurrentOrder = document;

        // Update the remote server with the queued order
        const response = await fetch(updateRemoteOrderAPIUrl, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr('content')
            },
            method: "POST",
            credentials: "same-origin",
            body: JSON.stringify(document)
        });

        // Handle the response
        if (response.ok) {
            myEmitter.emit('OrderSavedToRemoteServerFromQue');
        } else {
            myEmitter.emit('ErrorUpdatingRemoteDB');
        }
    } catch (error) {
        console.error('Error updating order on NLN server:', error);
        myEmitter.emit('ErrorUpdatingRemoteDB');
    } finally {
        // Ensure this emitter is triggered after processing
        setTimeout(() => {
            myEmitter.emit('finishedGettingLocalOrder');
        }, 500);
    }
};

/**
 * Updates an order on the NLN server by fetching it from the local database.
 * @param {object} orderData - The order data to be updated.
 */
window.updateOrderOnNLNServer = async (orderData) => {
    try {
        // Fetch the current order from the orders database
        const document = await db.collection(ordersDbName).doc({ id: +orderData.id }).get();
        window.CurrentOrder = document;

        // Send the current order to the remote server
        const response = await fetch(updateRemoteOrderAPIUrl, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr('content')
            },
            method: "POST",
            credentials: "same-origin",
            body: JSON.stringify(document)
        });

        // Handle the response
        if (response.ok) {
            myEmitter.emit('OrderSavedToRemoteServer');
        } else {
            myEmitter.emit('ErrorUpdatingRemoteDB');
        }
    } catch (error) {
        console.error('Error updating order on NLN server:', error);
        myEmitter.emit('ErrorUpdatingRemoteDB');
    } finally {
        // Always emit the final event after the process
        setTimeout(() => {
            myEmitter.emit('finishedGettingLocalOrder');
        }, 500);
    }
};

/**
 * Checks if an order is in the queue. If found, it displays the queued order;
 * otherwise, it adds the order to the new orders database.
 * @param {number} orderID - The ID of the order to check.
 * @param {object} order - The order data to add if it's not in the queue.
 */
window.isOrderInQue = async (orderID, order) => {
    try {
        // Check if the order exists in the queued orders database
        const document = await db.collection(queuedOrdersDbName).doc({ id: +orderID }).get();

        if (document) {
            // Display the queued order if found
            window.displayQueuedOrder(order);
        } else {
            // Add the order to the new orders database if it doesn't exist in the queue
            await db.collection(ordersDbName).add(order, String(orderID));
        }
    } catch (error) {
        console.error('Error checking order in queue:', error);
        return false;
    }
};

/**
 * Populates the local database with remote orders, classifies orders and queues them if needed.
 * @param {Array} data - The data containing remote orders to be added to the local database.
 */
window.putRemoteOrdersInLocalDB = async (data) => {
    try {
        // Reset the orders database
        await db.collection(ordersDbName).set([]);

        const orderObjects = JSON.parse(JSON.stringify(data));

        if (orderObjects.length === 0) {
            // If no orders, emit completion and hide the wait indicator
            $('#wait').fadeOut(100);
            myEmitter.emit('finishedPopulatingLocalOrdersDB');
            return;
        }

        // Process each order
        for (let i = 0; i < orderObjects.length; i++) {
            const order = orderObjects[i];

            // Determine order class based on due date
            const daysTillDue = order['due_in_days'];
            order['orderClass'] =
                daysTillDue === 0 ? "dueToday" :
                    daysTillDue < 0 ? "pastDue" :
                        "none";

            // Parse options
            let options = JSON.parse(order['json_form_options'] || '{}');
            const postProperty = options.Post !== false;

            order['postProperty'] = postProperty;

            // Queue the order if postProperty is true
            if (postProperty) {
                window.isOrderInQue(order.id, order);
            }

            // Emit completion when all orders are processed
            if (i + 1 === orderObjects.length) {
                setTimeout(() => {
                    myEmitter.emit('finishedPopulatingLocalOrdersDB');
                }, readDelay * 2);
                $('#wait').fadeOut(10);
            }
        }
    } catch (error) {
        console.error('Error populating the local orders database:', error);
    }
};

/**
 * Retrieves new orders from the local database and sets them to the `window.orders`.
 * @returns {Promise<Array>} Resolves with the list of orders.
 */
window.getNewOrdersLocal = async () => {
    try {
        const orders = await db.collection(ordersDbName).get();
        window.orders = orders; // Sets the orders globally
        return orders; // Returns the orders for further chaining or usage
    } catch (error) {
        console.error('Error fetching orders from the local database:', error);
        return []; // Returns an empty array as a fallback
    }
};

/**
 * Clears all images from the new images database.
 */
window.clearImagesFromNewImages = async () => {
    try {
        await db.collection(newPhotosDbName).delete();
        console.log('New images successfully cleared.');
    } catch (error) {
        console.error('Error clearing images from new images database:', error);
    }
};

/**
 * Removes specified images from the new images database.
 * @param {Array|string} removeImageArray - An array or JSON string containing image identifiers to remove.
 */
window.removeImagesFromNewImages = (removeImageArray) => {
    try {
        const images = Array.isArray(removeImageArray)
            ? removeImageArray
            : JSON.parse(removeImageArray);

        images.forEach((image) => {
            window.deleteImageFromLocalDB(image);
            console.log(`Image: ${image} was deleted`);
        });
    } catch (error) {
        console.error('Error removing images from new images database:', error);
    }
};

/**
 * Sends a captured photo to the server for uploading.
 * @param {string} key - The unique key identifying the photo to be sent.
 */
window.sendCapturedPhoto = async function (key) {
    try {
        // Retrieve the photo from the database using the specified key
        const photo = await db.collection(capturedPhotosDbName).doc({ key }).get();

        if (!photo) {
            console.warn('No photo found for the given key:', key);
            return;
        }

        console.log('Fetch Upload');

        // Send the photo to the server using fetch
        const response = await fetch(getCapturedPhotoUploadUrl, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr('content')
            },
            method: "POST",
            credentials: "same-origin",
            body: JSON.stringify(photo),
        });

        const data = await response.text();
        console.log('Upload response:', data);

        // Emit event after successfully uploading the photo
        myEmitter.emit('capturedPhotoUploaded');
    } catch (error) {
        console.error('Error during photo upload:', error);

        // Emitting or logging can be added here if required
        // Example:
        // myEmitter.emit('ErrorUploadingImages');
    }
};

/**
 * Sends all queued photos in the local database to the server in bulk.
 */
window.sendQueuedPhotos = async function () {
    try {
        // Retrieve queued photos from the local database
        const photos = await db.collection(newPhotosDbName).get();

        if (!photos || photos.length === 0) {
            console.log('No queued photos available to upload.');
            return;
        }

        console.log('Uploading queued photos...');

        // Make a POST request to upload photos to the server
        const response = await fetch(getUploadBulkImagesUrl, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr('content'),
            },
            method: "POST",
            credentials: "same-origin",
            body: JSON.stringify(photos),
        });

        const data = await response.text();
        console.log('Upload Response:', data);

        // Remove successfully uploaded images from the local database
        window.removeImagesFromNewImages(data);

        // Update UI and emit an event to signal completion
        $('#queuedPhotoIndicator').fadeOut();
        myEmitter.emit('ClearNewPhotosDBClearRequested');
    } catch (error) {
        console.error('Error while uploading queued photos:', error);

        // Handle upload failures with fallback actions
        $('#queuedPhotoIndicator').fadeIn();
        // Optional: Emit an error-related event if needed
        // myEmitter.emit('ErrorUploadingImages');
    }
};

/**
 * Clears the new photos collection from the local database.
 */
window.clearNewPhotosCollection = async () => {
    try {
        // Delete all entries in the newPhotosDbName collection
        await db.collection(newPhotosDbName).delete();

        // Emit success event and log the outcome
        myEmitter.emit('NewPhotosDBCleared');
        console.log('New Photos Collection successfully deleted.');
    } catch (error) {
        // Emit failure event and log the error
        myEmitter.emit('NewPhotosDBCouldNotBeCleared');
        console.error('Error: New Photos Collection could not be deleted.', error);
    }
};

/**
 * Clears the queued orders collection from the local database.
 */
window.clearQueuedOrdersLocalDB = async () => {
    try {
        // Delete all entries in the queuedOrdersDbName collection
        await db.collection(queuedOrdersDbName).delete();

        // Emit success event and log the result
        myEmitter.emit('LocalQueuedOrdersDBCleared');
        console.log('LocalQueuedOrdersDBCleared successfully.');
    } catch (error) {
        // Emit failure event and log the error
        myEmitter.emit('LocalQueuedOrdersDBNotCleared');
        console.error('Error: LocalQueuedOrdersDB could not be cleared.', error);
    }
};

/**
 * Fetches new photos from the local database and sets them to the global `photos` variable.
 */
window.getNewPhotos = async () => {
    try {
        // Fetch photos from the local database collection
        const photos = await db.collection(newPhotosDbName).get();

        // Assign photos to the global window object
        window.photos = photos;

        // Emit an event after a delay to signal that photos have been fetched
        setTimeout(() => {
            myEmitter.emit('LocalPhotosFetchedFromClientLocalBD');
            console.log('Local Photos fetched successfully.');
        }, readDelay);
    } catch (error) {
        console.error('Error fetching new photos from the local database:', error);
    }
};

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}



/**
 * Fetches captured photos from the local database and assigns them to the global `capturedPhotos` variable.
 * Emits an event to signal that the photos have been fetched.
 *
 * @returns {Array} The fetched photos, or an empty array in case of error.
 */
window.getCapturedPhotos = async () => {
    try {
        // Fetch captured photos from the local database
        const photos = await db.collection(capturedPhotosDbName).get();

        // Assign photos to the global window object
        window.capturedPhotos = photos;

        // Emit an event after a delay to signal photos have been fetched
        setTimeout(() => {
            myEmitter.emit('CapturedPhotosFetchedFromClientLocalBD');
            console.log('Captured photos fetched successfully.');
        }, readDelay);

        return photos;
    } catch (error) {
        // Log error and return an empty array as a fallback
        console.error('Error fetching captured photos from the local database:', error);
        return [];
    }
};


/**
 * Retrieves the count of queued photos from the captured photos collection.
 * This function is globally accessible through the window object.
 * Used to check if photo capture view should be displayed.
 *
 * @global
 * @function getQueuedPhotoCount
 * @async
 * @returns {Promise<number>} The number of photos in the queue
 *                           Returns 0 if collection is empty
 * @throws {Error} When database operation fails
 *
 * @example
 * // Check if photos view should be hidden
 * if(await window.getQueuedPhotoCount() === 0) {
 *     $('#photosCaptured').css('display', 'none');
 * }
 *
 * @description
 * This function connects to the database and counts the number of photos
 * in the captured photos collection. It's commonly used to determine if
 * the photo capture interface should be displayed to the user.
 */
window.getQueuedPhotoCount = async function() {
    try {
        // Get collection
        const photos = await db.collection(newPhotosDbName).get();

        // Return the length/size of the collection
        return photos?.length || 0;

    } catch (error) {
        console.error('Error counting photos:', error);
        throw new Error('Failed to count photos in collection');
    }
}

/**
 * Deletes captured photos from the local database that are older than a specified number of days.
 */
window.deleteLocalPhotosOverThirtyDaysOld = async () => {
    const daysToKeepPhotos = 5; // Number of days to keep photos
    const thirtyDaysInMs = daysToKeepPhotos * 24 * 60 * 60 * 1000; // Convert days to milliseconds

    try {
        // Fetch all captured photos from the database
        const photos = await db.collection(capturedPhotosDbName).get();
        const now = new Date();

        // Process each photo
        for (const photo of photos) {
            const fileName = photo['fileName'].split(".");
            const photoTakenDateTime = +fileName[1];

            // Parse the photo's date and calculate the age
            const datePhotoTaken = new Date(photoTakenDateTime);
            const timeDiffInMs = now.getTime() - datePhotoTaken.getTime();

            if (timeDiffInMs >= thirtyDaysInMs) {
                console.log(`${datePhotoTaken} : ${photo['key']} SHOULD BE DELETED`);

                // Attempt to delete the photo from the database
                try {
                    await db.collection(capturedPhotosDbName)
                        .doc({ fileName: photo['fileName'] })
                        .delete();

                    console.log('Delete Image successful.');
                } catch (deleteError) {
                    console.error('Error deleting image:', deleteError);
                }
            }
        }

        // Emit event after processing all photos
        myEmitter.emit('ClearedCapturedImagesOver30');
        console.log('Old captured photos cleanup completed.');
    } catch (error) {
        console.error('Error fetching photos for deletion:', error);
    }
};

/**
 * Fetches a specific order by its ID from the local database and assigns it to the global `CurrentOrder` variable.
 * Emits an event after successfully fetching the order.
 *
 * @param {number} OrderID - The ID of the order to retrieve.
 */
window.getNewOrder = async function (OrderID) {
    try {
        // Fetch the order document from the database
        // Assign the fetched order to a global variable
        window.CurrentOrder = await db.collection(ordersDbName).doc({id: +OrderID}).get();

        // Emit an event after a short delay
        setTimeout(() => {
            myEmitter.emit('finishedGettingLocalOrder');
            console.log(`Order with ID ${OrderID} fetched successfully.`);
        }, 300);
    } catch (error) {
        // Log an error message in case of failure
        console.error(`Error fetching order with ID ${OrderID}:`, error);
    }
};

/**
 * Checks connectivity by making a fetch request to the specified URL and emits events based on the response.
 */
window.checkConnectivity = async () => {
    console.log('Checking Connectivity...');

    try {
        // Make a fetch request to the connectivity API
        const response = await fetch(checkConnectivityAPIUrl);

        if (!response) {
            // Emit an event when the response is not available
            myEmitter.emit('finishedFailedSendOrderPreTest');
            return;
        }

        console.log('Connectivity Response:', response);
        const jsonResponse = await response.json();
        const connectionResults = jsonResponse || {};

        if (connectionResults.result === 'success') {
            // Emit success event
            myEmitter.emit('finishedSuccessfulSendOrderPreTest');
        } else {
            // Handle failed network check
            console.log('Adding To Queue - Failed Network Check');
            myEmitter.emit('finishedSuccessfulSendOrderPreTest'); // Redundant here, but kept as per logic
            myEmitter.emit('finishedFailedSendOrderPreTest');
        }
    } catch (err) {
        // Handle network or fetch error
        console.error('Fetch Error:', err);
        myEmitter.emit('finishedSuccessfulSendOrderPreTest'); // Redundant here, but kept as per logic
        myEmitter.emit('finishedFailedSendOrderPreTest');
    }
};

/**
 * Initializes the driver's home by syncing orders for the given driver ID.
 *
 * @param {number} DriverID - The ID of the driver to initialize.
 */
window.initDriverHome = DriverID => window.syncOrders(DriverID);